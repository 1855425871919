import styled from 'styled-components';

import GridContainer from '@glowforge/gf-styled/src/components/grid-container/grid-container';
import {
  Heading as UnstyledHeading,
} from '@glowforge/gf-styled/src/components/typography';
import SVG from '@glowforge/gf-styled/src/components/svg';

export const Background = styled(GridContainer)`
  display: none;
  background-color: ${(props) => props.backgroundColor};

  @media ${(props) => props.theme.queries.desktopAndUp} {
    display: grid;
    width: 100%;
    min-height: 300px;
  }
  @media ${(props) => props.theme.queries.desktopDown} {
    min-height: 300px;
  }
`;

export const CarouselContainer = styled('div')`
  grid-column: main-start / main-end;
  padding-bottom: 0px;
  & * {
    /*
      Don't allow users to select text in carousel items
      This is a better UX
    */
    user-select: none;
  }

  @media ${(props) => props.theme.queries.desktopDown} {
    padding-bottom: 0;
  }
`;

export const LogoRow = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 42px;
`;

export const LogoWrapper = styled('div')`
  fill: ${(props) => {
    if(props.active) return props.theme.colors.magic.base;
    return '#919191';
  }};
  @media ${(props) => props.theme.queries.desktopDown} {
    margin: 0 auto;
    display: ${(props) => props.active ? 'block' : 'none'};
  }
`;

export const Logo = styled(SVG)`
  &&& {
    /* Remove all styling supplied by SVG component. */
    fill: inherit;
    transition: fill 0.25s ease-in-out;
    stroke: none;

    /* fixes safari bug; svgs as children of flex need explicit height/width */
    width: 150px;
    max-width: 100%;
  }
  cursor: pointer;
`;

export const PaginationWrapper = styled('div')`
  padding-top: 48px;
  padding-bottom: 0;
  display: flex;
  justify-content: center;
`;

export const Wrapper = styled('div')`
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  grid-column: main-start / main-end;
  padding: 0;
  flex-grow: 1; /* grow to fill height so items can stretch with space-between. prevents shifting */
                /* when text is differing lengths/wrapping */
`;

export const Heading = styled(UnstyledHeading)`
  text-align: center;
  padding-bottom: 0;

  --font-weight: normal;
  @media ${(props) => props.theme.queries.desktopDown} {
    font-size: 20px;
  }
`;
