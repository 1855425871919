/**
 * @type {import('@builder.io/sdk/dist/src/builder.class').Component}
 */
export default {
  name: 'newsClips',
  friendlyName: 'News Clips',
  // shows options when editing children
  broadcast: true,
  noWrap: true,
  defaultStyles: {},
  image:
    'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2Fb3aef0e3cc06441697317ef8a455dae3',
  inputs: [
    {
      type: 'number',
      name: 'transitionDelay',
      defaultValue: 6000,
      helperText:
        'This is in milliseconds. The transition delay between carousel items.',
    },
    {
      type: 'list',
      name: 'newsItems',
      defaultValue: [
        {
          logo: 'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2Fb3aef0e3cc06441697317ef8a455dae3',
          source: 'Source Placeholder',
          quote: 'This is a quote placeholder... Awesome!',
        },
      ],
      subFields: [
        {
          name: 'logo',
          friendlyName: 'SVG Logo',
          type: 'file',
          allowedFileTypes: ['svg'],
        },
        {
          name: 'source',
          friendlyName: 'News Source',
          type: 'string',
          helperText:
            'The news organization or site that this quote comes from',
        },
        {
          name: 'quote',
          type: 'string',
        },
      ],
    },
  ],
};
