import React from 'react';
import PropTypes from 'prop-types';

import useCarousel from '@glowforge/gf-styled/src/hooks/useCarousel';
import ButtonLinkWidget from '@glowforge/gf-styled/src/contentful-widgets/button-link';
import { useRouter } from 'next/router';

import {
  BottomContent,
  CarouselWrapper,
  ImageContainer,
  ImageV2,
  Subheading,
  BackgroundGradient,
  Wrapper,
} from './unique-creations-mobile.styles';

const UniqueCreations = ({
  buttonLink,
  images,
  backgroundImage,
  backgroundColor,
  backgroundSize,
  backgroundRepeat,
}) => {
  const { Carousel, carouselProps, PaginationDots } = useCarousel();
  const router = useRouter();

  return (
    <Wrapper
      showOverlay={false}
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      backgroundSize={backgroundSize}
      backgroundRepeat={backgroundRepeat}
      variant='full'>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Carousel {...carouselProps} Wrapper={CarouselWrapper}>
        {/*
          Slicing here because current Carousel doesn't truncate its pagination.
          This means that anything beyond 4 slides looks pretty gnarly.
        */}
        {images.slice(0, 5).map((image, index) => (
          <ImageContainer
            key={image?.fields?.title}
            onClick={() =>
              image?.fields?.url && router.replace(image?.fields?.url)
            }>
            <ImageV2
              media={image}
              height={600}
              quality={70}
              index={index}
              lazy
            />
            <BackgroundGradient />
            {/* Don't show title on first one  */}
            {index !== 0 ? (
              <Subheading>{image?.fields?.title}</Subheading>
            ) : null}
          </ImageContainer>
        ))}
      </Carousel>
      <BottomContent>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <PaginationDots {...carouselProps} />
        {buttonLink ? <ButtonLinkWidget model={buttonLink} /> : null}
      </BottomContent>
    </Wrapper>
  );
};

const imageShape = PropTypes.shape({
  description: PropTypes.string,
  file: PropTypes.shape({
    fileName: PropTypes.string,
    contentType: PropTypes.string,
    url: PropTypes.string,
    details: PropTypes.shape({
      size: PropTypes.number,
      image: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number,
      }),
    }),
  }),
  title: PropTypes.string,
});

UniqueCreations.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      default: imageShape,
      mobile: imageShape,
    })
  ),
  backgroundImage: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundSize: PropTypes.string,
  backgroundRepeat: PropTypes.string,
  buttonLink: PropTypes.shape({
    fields: PropTypes.shape({
      text: PropTypes.string,
    }),
  }),
};

UniqueCreations.defaultProps = {
  images: [],
  backgroundImage: null,
  backgroundColor: null,
  backgroundSize: null,
  backgroundRepeat: null,
  buttonLink: {},
};

export default UniqueCreations;
