import React from 'react';
import PropTypes from 'prop-types';

import { contentfulImageShape } from '../../util/contentful-data';
import MobileLayout from './mobile-layout';
import DesktopLayout from './desktop-layout';

const UniqueCreations = (props) => (
    <>
      <MobileLayout {...props} />
      <DesktopLayout {...props} />
    </>
  );

UniqueCreations.propTypes = {
  attributes: PropTypes.shape({}),
  isBuilder: PropTypes.bool,
  title: PropTypes.string.isRequired,
  expandButtonText: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      default: contentfulImageShape,
      mobile: contentfulImageShape,
    }),
  ),
  backgroundImage: PropTypes.string,
  buttonLink: PropTypes.shape({
    fields: PropTypes.shape({})
  }),
};

UniqueCreations.defaultProps = {
  attributes: {},
  isBuilder: false,
  images: [],
  backgroundImage: null,
  buttonLink: {},
};

export default UniqueCreations;
