// @ts-check
import React from 'react';
import PropTypes from 'prop-types';

import FeaturesDisplay from './features-display-rebrand';

const FeaturesDisplayWidget = ({ transitionDelay, features, sectionTitle, attributes }) => (
  <FeaturesDisplay
    attributes={attributes}
    isBuilder
    delay={transitionDelay}
    features={features}
    sectionTitle={sectionTitle}
  />
);

FeaturesDisplayWidget.propTypes = {
  attributes: PropTypes.shape({}).isRequired,
  sectionTitle: PropTypes.string,
  transitionDelay: PropTypes.number,
  features: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    bodyContent: PropTypes.string,
    image: PropTypes.string,
    backgroundImage: PropTypes.string,
    backgroundColor: PropTypes.string,
  })),
};

FeaturesDisplayWidget.defaultProps = {
  transitionDelay: 6000,
  features: [],
  sectionTitle: '',
};

export default FeaturesDisplayWidget;
