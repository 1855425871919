import React from 'react';
import PropTypes, { string } from 'prop-types';
import { useTheme } from 'styled-components';

import useCarousel from '@glowforge/gf-styled/src/hooks/useCarousel';

import {
  Background,
  CarouselContainer,
  Heading,
  Logo,
  LogoRow,
  PaginationWrapper,
  Wrapper,
  LogoWrapper,
} from './news-clips.styles';

const NewsClips = ({ attributes, isBuilder, delay, clips }) => {
  const { colors } = useTheme();
  const { carouselProps, Carousel, PaginationDots } = useCarousel(delay);
  const { activeIndex, setActiveIndex } = carouselProps;

  const logoRow = (
    <LogoRow>
      {clips.map((clip, index) => (
        <LogoWrapper
          key={clip.source}
          active={index === activeIndex}>
          <Logo
            onClick={() => setActiveIndex(index)}
            svgAsset={clip.logo}
            height='36px'
            width='100%'
          />
        </LogoWrapper>
        ))}
    </LogoRow>
  )

  const pagination = clips.length > 1 ? (
    <PaginationWrapper>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <PaginationDots {...carouselProps} />
    </PaginationWrapper>
  ): null

  return (
    <Background
      backgroundColor={isBuilder ? '' : colors.slate.base}
      showOverlay={false}
      variant='full'
      {...attributes}
      className={`fullWidth ${attributes?.className}`}>
      <Wrapper>
        {logoRow}
        <Carousel {...carouselProps} Wrapper={CarouselContainer}>
          {clips.map((clip) => (
            <React.Fragment key={clip.source}>
              <Heading>
                &ldquo;
                {clip.quote}
                &rdquo;
              </Heading>
            </React.Fragment>
          ))}
        </Carousel>
        <div style={{ width: '100%' }}>
          {pagination}
        </div>
      </Wrapper>
    </Background>
  );
};

NewsClips.propTypes = {
  attributes: PropTypes.shape({
    className: PropTypes.string,
  }),
  isBuilder: PropTypes.bool,
  delay: PropTypes.number,
  clips: PropTypes.arrayOf(
    PropTypes.shape({
      logo: PropTypes.oneOfType([
        PropTypes.shape({
          fields: PropTypes.shape({
            file: PropTypes.shape({
              url: PropTypes.string,
            }),
          }),
        }),
        PropTypes.string,
      ]),
      quote: string,
      title: string,
    })
  ),
};

NewsClips.defaultProps = {
  delay: undefined,
  clips: [],
  attributes: { className: '' },
  isBuilder: false,
};

export default NewsClips;
