import get from 'lodash/get'
import reduce from 'lodash/reduce'
import find from 'lodash/find'
import PropTypes from 'prop-types';

/**
 * Helpers for retrieving data from the deeply nested structures returned from
 * the Contentful API
 */

/**
 * Helper to define a default way of extracting values from an object using a
 * country code.
 * @param {String} key A key for the default string in the hash object
 * @param {String} countryCode Appended to key to build a country specific key
 * @param {Object} hash Object of anything
 */
export const getValueFromCountryCode = (key, countryCode, hash) => {
  let value = hash[`${key}-${countryCode}`];
  if (!value) {
    value = hash[key];
  }
  return value;
};

/**
 * Get the type for a Contentful Entry
 * @param {any} object Any Contentful content Entry
 */
export const getContentType = (object) => get(object, 'sys.contentType.sys.id');

/**
 * Take a page object and return an object that contains only plain text
 * fields for the page.
 * @param {any} page Contentful page
 */
export const getStringsForPage = (page = {}) => (
  reduce(page.unorderedShortText, (all, string) => {
    if (getContentType(string) === 'basicTextString') {
      // eslint-disable-next-line no-param-reassign
      all[get(string.fields, 'id')] = get(string.fields, 'text');
    }
    return all;
  }, {})
);

export const getJSONForPage = (page = {}) => (
  reduce(page.json, (all, json) => {
      // eslint-disable-next-line no-param-reassign
      all[get(json.fields, 'lookupId')] = get(json.fields, 'jsonData');
    return all;
  }, {})
);

/**
 * Take a page object and return an object that contains only markdown text
 * fields for the page.
 * @param {any} page Contentful page
 */
export const getMarkdownStringsForPage = (page = {}) => (
  reduce(page.unorderedShortText, (all, string) => {
    if (getContentType(string) === 'textString') {
      // eslint-disable-next-line no-param-reassign
      all[get(string.fields, 'id')] = get(string.fields, 'text');
    }
    return all;
  }, {})
);

/**
 * Get section by id
 * @param {any} page Contentful page
 * @param {string} id Contentful section id
 */
// eslint-disable-next-line
export const getSectionById = (page = {}, id) => (
  find(get(page, 'fields.sections', []), (section) => (
    get(section, 'fields.id') === id || get(section, 'fields.lookupId') === id
  ))
);

export const contentfulImageShape = PropTypes.shape({
  description: PropTypes.string,
  file: PropTypes.shape({
    fileName: PropTypes.string,
    contentType: PropTypes.string,
    url: PropTypes.string,
    details: PropTypes.shape({
      size: PropTypes.number,
      image: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number,
      }),
    }),
  }),
  title: PropTypes.string,
});
