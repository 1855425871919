/* eslint-disable max-len */
import { allowedColors } from '@glowforge/gf-styled/src/theme/constants/colors';

/**
 * @type {import('@builder.io/sdk/dist/src/builder.class').Component}
 */
 export default {
  name: 'featuresDisplayHome',
  friendlyName: 'Features Display Homepage',
  description: 'Ensure Section that wraps this component does not have a maxWidth and no horizontal padding. This component has its own grid set up',
  // shows options when editing children
  broadcast: true,
  noWrap: true,
  defaultStyles: {
    display: 'grid'
  },
  image:
    'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2Fdf1f2ec0729a46c98e2155c6ec27aa8b?quality=60&width=200&height=200',
  inputs: [
    {
      type: 'number',
      name: 'transitionDelay',
      defaultValue: 6000,
      helperText:
        'This is in milliseconds. The transition delay between carousel items.',
    },
    {
      type: 'string',
      name: 'sectionTitle',
      friendlyName: 'title',
      // eslint-disable-next-line max-len
      helperText: 'This is the section title. It is a string option because it is integrated in the text. If you\'d like an alternate treatment, make this blank and add a Text component before this one.',
      defaultValue: 'Features that set Glowforge apart'
    },
    {
      type: 'list',
      name: 'features',
      subFields: [
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpg', 'jpeg', 'png', 'webp', 'svg'],
        },
        {
          name: 'title',
          type: 'string',
          helperText: 'Headline'
        },
        {
          name: 'bodyContent',
          friendlyName: 'Description',
          type: 'string',
          helperText:
            'Longer text description',
        },
        {
          name: 'backgroundImage',
          type: 'file',
          allowedFileTypes: ['jpg', 'jpeg', 'png', 'webp', 'svg'],
        },
        {
          friendlyName: 'Background Color',
          name: 'backgroundColor',
          type: 'string',
          enum: allowedColors,
          defaultValue: 'magic.base',
        },
      ],
      // created in builder.io ui, then downloaded as json and copied these values.
      defaultValue: [
        {
          "image": "https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F042295b22ccb4d0faee31231c62a8a05",
          "title": "Live Camera Preview",
          "bodyContent": "See your design take shape before you print. Onboard cameras show you a preview of your design on whatever material you choose. Place your design with your mouse or finger and position it exactly where you want.",
          "backgroundImage": "https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F31ccb154abf7404b833920f900e71295",
          "backgroundColor": "magic.dark"
        },
        {
          "image": "https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F806a7a33af1e4dfa836f3c7950936a26",
          "title": "3D Autofocus",
          "bodyContent": "Just like a camera, your Glowforge has a fully automatic focusing system to cut and engrave on materials both thick and thin. Before each print, the height is measured with exacting precision to be sure it prints perfectly, every time.",
          "backgroundImage": "https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F31ccb154abf7404b833920f900e71295",
          "backgroundColor": "magic.dark"
        },
        {
          "image": "https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F259d098ef9d041a1969a8bf8f1c1fb3d",
          "title": "3D Hi-Res Engraving",
          "bodyContent": "Engrave complex, three dimensional curves with 1,000 DPI resolution. To get perfect detail and sculpt with real depth, Glowforge can carve away material with multiple passes for truly detailed and impressive results.",
          "backgroundImage": "https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F31ccb154abf7404b833920f900e71295",
          "backgroundColor": "magic.dark"
        },
        {
          "image": "https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2Faa5fa7caafea45648db6341150ae913d",
          "title": "Laser Precision",
          "bodyContent": "Your Glowforge motors can control motion to a thousandth of an inch, and the laser can focus down to the width of a human hair. Combined, they can create prints with exquisite detail and precision.",
          "backgroundImage": "https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F31ccb154abf7404b833920f900e71295",
          "backgroundColor": "magic.dark"
        },
        {
          "image": "https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F285d14dd6260480dac0170e9eda4646c",
          "title": "Design with just a pen",
          "bodyContent": "Just use a pen to draw directly onto your materials, and watch your designs transform. From a doodle to a masterpiece, onboard cameras scan and turn your drawing into a real product. Resize, copy, or personalize it further – you’re just one button away from a beautiful print.",
          "backgroundImage": "https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F31ccb154abf7404b833920f900e71295",
          "backgroundColor": "magic.dark"
        },
        {
          "image": "https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F1b4857c731ec452bad4bec7c152d3b2e",
          "title": "Cloud simplicity",
          "bodyContent": "Your Glowforge runs from a web browser, which means there’s no software to install - ever. Drag and drop your designs right onto your material. Upgrades are instant and seamless.",
          "backgroundImage": "https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F31ccb154abf7404b833920f900e71295",
          "backgroundColor": "magic.dark"
        },
        {
          "image": "https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2Fb0b99a4db63745b18e856b34c9948528",
          "title": "Automatic Setup",
          "bodyContent": "Whether you use a thick natural leather or beautiful maple, Proofgrade™ brand materials are automatically recognized by your device. Material settings are instantly configured so you can focus on what matters: your project.",
          "backgroundImage": "https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F31ccb154abf7404b833920f900e71295",
          "backgroundColor": "magic.dark"
        }
      ],
    },
  ],
};
