import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import ButtonLinkWidget from '@glowforge/gf-styled/src/contentful-widgets/button-link';
import UniqueCreationImage from './unique-creation-desktop-image';

import {
  WrapperGridWithBackground,
  WrapperV2,
  LayoutGrid,
  ButtonWrapper,
} from './unique-creations-desktop.styles';

const UniqueCreations = ({
  buttonLink,
  images,
  backgroundColor,
  backgroundImage,
  backgroundSize,
  backgroundRepeat,
  isBuilder,
}) => {
  const isExpanded = true;

  useEffect(() => {
    /*
      We want to move our user's focus to the newly created anchor tag.
      This way screen readers will properly "see" the new tag and announce it.
    */
    if (isExpanded) {
      if (isBuilder) {
        const link = document.querySelector('.unique-creations-expanded-link');
        if (link) link.focus();
      } else {
        const links = document.getElementsByTagName('a');
        /* eslint-disable-next-line no-restricted-syntax */
        for (const link of links) {
          if (link.innerHTML === buttonLink?.fields?.text) {
            link.focus();
            break;
          }
        }
      }
    }
  }, [isExpanded, buttonLink?.fields?.text, isBuilder]);

  return (
    <WrapperV2
      initial={{ height: '990px' }}
      animate={{ height: isExpanded || '990px' }}
      transition={{ stiffness: 150 }}>
      <WrapperGridWithBackground
        showOverlay={false}
        backgroundColor={backgroundColor}
        backgroundImage={backgroundImage}
        backgroundSize={backgroundSize}
        backgroundRepeat={backgroundRepeat}
        variant='full'>
        <LayoutGrid isBuilder={isBuilder}>
          {images.map((image, index) => {
            const height = index === 4 ? 600 : 381;
            return (
              <UniqueCreationImage
                key={image?.fields?.title || index}
                {...{ image, index, height }}
              />
            );
          })}
        </LayoutGrid>
      </WrapperGridWithBackground>

      <ButtonWrapper isExpanded={isExpanded}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {isBuilder ? buttonLink : <ButtonLinkWidget model={buttonLink} />}
      </ButtonWrapper>
    </WrapperV2>
  );
};

const imageShape = PropTypes.shape({
  description: PropTypes.string,
  file: PropTypes.shape({
    fileName: PropTypes.string,
    contentType: PropTypes.string,
    url: PropTypes.string,
    details: PropTypes.shape({
      size: PropTypes.number,
      image: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number,
      }),
    }),
  }),
  title: PropTypes.string,
});

UniqueCreations.propTypes = {
  attributes: PropTypes.shape({}),
  isBuilder: PropTypes.bool,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      default: imageShape,
      mobile: imageShape,
    })
  ),
  backgroundImage: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundSize: PropTypes.string,
  backgroundRepeat: PropTypes.string,
  buttonLink: PropTypes.shape({
    fields: PropTypes.shape({
      text: PropTypes.string,
    }),
  }),
};

UniqueCreations.defaultProps = {
  attributes: {},
  isBuilder: false,
  images: [],
  backgroundImage: null,
  backgroundColor: null,
  backgroundSize: null,
  backgroundRepeat: null,
  buttonLink: {},
};

export default UniqueCreations;
