import React from 'react';
import PropTypes from 'prop-types';

import Image from '../contentful-views/image';
import { Heading } from '../typography/Typography';

import {
  Background,
  BodyContentMarkdown,
  FloatQuote,
  ImageContainer,
  TextContainer,
  TextContentWrapper,
  Wrapper,
} from './text-and-image.styles';

const TextAndImage = ({
  // ctaButton,
  // ctaPosition, <- maybe just have one position possible for simplicity
  variant,
  image,
  title,
  bodyContent,
  backgroundImage,
  backgroundColor,
  button,
  showQuote,
  minHeightMobile,
  minHeightDesktop,
  ...delegated
}) => {
  const renderTitle = title ? <Heading as='h2'>{title}</Heading> : null;
  const renderBodyContent = bodyContent ? <BodyContentMarkdown source={bodyContent} /> : null;
  const renderImage = image && (typeof image === 'object' || typeof image === 'string')
  ? (
    <ImageContainer variant={variant}>
      <Image media={image} height={400} quality={70} lazy imageProps={{draggable: 'false'}} />
    </ImageContainer>
  ) : null;

  // We want to provide the complimentary color for our backgroundColor.
  const quoteColor =
    showQuote && backgroundColor && backgroundColor.split('.').length === 2
      ? `${backgroundColor.split('.')[0]}.lightest`
      : 'magic.lightest';

  return (
    <Wrapper {...delegated}>
      {renderImage}
      <TextContainer
        side={variant}
        backgroundImage={backgroundImage}
        backgroundColor={backgroundColor}
        backgroundImagePosition='alignLeft'
        minHeightMobile={minHeightMobile}
        minHeightDesktop={minHeightDesktop}
        showOverlay={false}>
        {showQuote ? <FloatQuote color={quoteColor}>&ldquo;</FloatQuote> : null}
        <Background backgroundImage={backgroundImage} />
        <TextContentWrapper variant={variant}>
          {renderTitle}
          {renderBodyContent}
          {button}
        </TextContentWrapper>
      </TextContainer>
    </Wrapper>
  );
};

TextAndImage.propTypes = {
  variant: PropTypes.oneOf(['left-image', 'right-image']),
  image: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
  ]).isRequired,
  title: PropTypes.string,
  bodyContent: PropTypes.string,
  backgroundImage: PropTypes.string,
  backgroundColor: PropTypes.string,
  button: PropTypes.shape({}),
  minHeightDesktop: PropTypes.string,
  minHeightMobile: PropTypes.string,
  showQuote: PropTypes.bool,
  quoteColor: PropTypes.string,
};

TextAndImage.defaultProps = {
  variant: 'right-image',
  title: null,
  bodyContent: null,
  backgroundImage: null,
  backgroundColor: null,
  button: null,
  minHeightMobile: '250px',
  minHeightDesktop: '400px',
  showQuote: false,
  quoteColor: 'magic.lightest',
};

export { ImageContainer, TextContainer };

export default TextAndImage;
