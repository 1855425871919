import styled from 'styled-components';

import {
  Title as UnstyledTitle,
  Paragraph as UnstyledParagraph,
} from '@glowforge/gf-styled/src/components/typography';
import ImageWidget from '@glowforge/gf-styled/src/components/contentful-views/image';
// eslint-disable-next-line max-len
import GridContainerWithBackground from '@glowforge/gf-styled/src/components/grid-container/grid-container-with-background';
import UnstyledSVG from '@glowforge/gf-styled/src/components/svg';

const SVG = styled(UnstyledSVG)`
  margin-left: 6px;
`;

const WrapperGridWithBackground = styled(GridContainerWithBackground)`
  padding-bottom: 0;
`;

const WrapperV2 = styled('div')`
  display: none;

  @media ${(props) => props.theme.queries.tabletAndUp} {
    /* Padding provides room for the lifted text here on v1 */
    padding-top: 0;
    position: relative;
    display: block;
    /* We want to hide any unseen grid items until we expand the Wrapper's height.*/
    overflow: hidden;
  }
`;

const Title = styled(UnstyledTitle)`
  @media ${(props) => props.theme.queries.tabletAndUp} {
    grid-area: text;
    /* Pull the Title text up a bit to be offset from the background. */
    margin-top: -50px;
    padding-bottom: 50px;
    color: ${(props) => props.theme.colors.white};
    position: absolute;
    --font-size-max: 65px;
    line-height: 80px;
    bottom: 0;
    left: 24px;
  }
`;

const LayoutGrid = styled('div')`
  display: grid;

  @media ${(props) => props.theme.queries.tabletAndUp} {
    grid-column: ${(props) =>
      props.isBuilder ? 'full-start / full-end' : 'main-start / main-end'};
    /* we provide an explicit template for our images */
    /* Their placement is statically driven by Design spec. */
    grid-gap: 16px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      'img0 img0 img1'
      'img0 img0 img1'
      'img0 img0 img4'
      'img2 img3 img4'
      'img2 img3 img4'
      'img2 img3 img4'
      'img5 img6 img7'
      'img5 img6 img7';
  }
`;

// V2 specific implementation
const ImageContainer = styled('div')`
  grid-area: ${(props) => `img${props.index}`};
  height: 'auto';
  align-self: ${(props) =>
    props.index === 4 || props.index === 0 ? 'stretch' : ''};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const BackgroundGradient = styled('div')`
  background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0%
    0% no-repeat padding-box;
  opacity: 0.25;
  position: absolute;
  inset: 0;
`;

const ImageV2 = styled(ImageWidget)`
  width: 100%;
  object-fit: cover;
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
`;

const Paragraph = styled(UnstyledParagraph)`
  position: absolute;
  text-align: right;
  bottom: 15px;
  right: 15px;
  left: 15px;
  color: white;
`;

const ButtonWrapper = styled('div')`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 128px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  &::before {
    pointer-events: none;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      0deg,
      ${(props) => props.theme.colors.maple.lighter},
      ${(props) =>
        props.theme.utils.getHexOpacity(props.theme.colors.maple.lighter, 90)},
      transparent
    );
    transition: opacity 0.2s ease-in-out;
    opacity: ${(props) => (props.isExpanded ? 0 : 1)};
  }
`;

const hoverState = `
  .content-blocks {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease;
  }
  &:hover .content-blocks {
    pointer-events: all;
    opacity: 1;
  }
`;

const ContentBox = styled('div')`
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${(props) => (props.hover ? hoverState : '')}
`;

export {
  WrapperGridWithBackground,
  WrapperV2,
  SVG,
  Title,
  LayoutGrid,
  ImageContainer, // V2
  ImageV2, // V2
  Paragraph, // V2
  ButtonWrapper,
  BackgroundGradient,
  ContentBox,
};
