// @ts-check
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { withChildren } from '@builder.io/react/lite';

import UniqueCreations from './unique-creations';

/**
 * children are used to render the expanded button
 */
const UniqueCreationsWidget = ({
  title,
  images,
  children = [],
  expandButtonText,
  builderBlock,
}) => {
  const massagedImages = useMemo(
    () =>
      images.map((i) => ({
        fields: {
          title: i.title,
          file: {
            url: i.image,
          },
          url: i.url,
          hoverState: i.hoverState,
          builderParentId: builderBlock.id,
          blocks: i.blocks,
        },
      })),
    [images, builderBlock]
  );

  return (
    <UniqueCreations
      isBuilder
      expandButtonText={expandButtonText}
      title={title}
      images={massagedImages}
      buttonLink={children}
    />
  );
};

UniqueCreationsWidget.propTypes = {
  title: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  children: PropTypes.node,
  expandButtonText: PropTypes.string,
  builderBlock: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

UniqueCreationsWidget.defaultProps = {
  title: '',
  images: [],
  children: null,
  expandButtonText: 'Expand',
};

export default withChildren(UniqueCreationsWidget);
