import React from 'react';
import PropTypes from 'prop-types';

import Link from '@glowforge/gf-styled/src/components/link/link';
import { BuilderBlocks } from '@builder.io/react/lite';

import {
  ImageContainer,
  BackgroundGradient,
  ImageV2,
  Title,
  Paragraph,
  ContentBox,
} from './unique-creations-desktop.styles';

const UniqueCreationImage = ({ image, index, height }) => {
  if (!image?.fields) return null;

  const { title, url, builderParentId, blocks, hoverState } = image.fields;

  const headline =
    index === 0 ? <Title>{title}</Title> : <Paragraph>{title}</Paragraph>;

  return (
    <ImageContainer
      index={index}
      as={url ? Link : 'div'}
      {...(url ? { href: url } : {})}>
      <ImageV2 media={image} index={index} height={height} quality={70} lazy />
      <BackgroundGradient />
      {title ? headline : null}
      <ContentBox hover={hoverState}>
        <BuilderBlocks
          className='content-blocks'
          key={index}
          child
          blocks={blocks}
          parentElementId={builderParentId}
          dataPath={`component.options.images.${index}.blocks`}
        />
      </ContentBox>
    </ImageContainer>
  );
};
UniqueCreationImage.propTypes = {
  image: PropTypes.shape({
    fields: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
      title: PropTypes.string,
      url: PropTypes.string,
      builderParentId: PropTypes.string.isRequired,
      blocks: PropTypes.arrayOf({}),
      hoverState: PropTypes.bool
    }),
  }),
  index: PropTypes.number,
  height: PropTypes.number,
};

UniqueCreationImage.defaultProps = {
  image: {
    fields: {
      file: {
        url: '',
      },
      title: '',
      url: '',
      builderParentId: '',
      blocks: '',
      hoverState: false
    },
  },
  index: 0,
  height: 0,
};

export default UniqueCreationImage;
