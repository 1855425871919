// @ts-check
import React from 'react';
import PropTypes from 'prop-types';

import NewsClips from './news-clips';

const NewsClipsWidget = ({ delay, newsItems, attributes }) => (
  <NewsClips
    isBuilder
    delay={delay}
    clips={newsItems}
    attributes={attributes}
  />
);

NewsClipsWidget.propTypes = {
  attributes: PropTypes.shape({}).isRequired,
  delay: PropTypes.number,
  newsItems: PropTypes.arrayOf(PropTypes.shape({
    logo: PropTypes.string,
    source: PropTypes.string,
    quote: PropTypes.string,
  })),
};

NewsClipsWidget.defaultProps = {
  delay: 6000,
  newsItems: [],
};

export default NewsClipsWidget;
