import styled from 'styled-components';

import { 
  Subheading as UnstyledSubheading,
} from '@glowforge/gf-styled/src/components/typography';
import GridContainerWithBackground 
  from '@glowforge/gf-styled/src/components/grid-container/grid-container-with-background';
import ImageWidget from '@glowforge/gf-styled/src/components/contentful-views/image';

const Wrapper = styled(GridContainerWithBackground)`
  @media ${(props) => props.theme.queries.tabletAndUp} {
    display: none;
  }
`;

const CarouselWrapper = styled('div')`
  grid-column: main-start / main-end;
  padding-top: 24px;
`;

// V2 stuff
const ImageContainer = styled('div')`
  height: calc(100vw * 0.8);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow:hidden;
`

const ImageV2 = styled(ImageWidget)`
  width: 100%;
  object-fit: cover;
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%
`

const Subheading = styled(UnstyledSubheading)`
  position: absolute;
  text-align: center;
  bottom: 15px;
  right: 15px;
  left: 15px;
  color: white;
`

const BackgroundGradient = styled('div')`
  background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0%
    0% no-repeat padding-box;
  opacity: 0.25;
  position: absolute;
  inset: 0;
`

const BottomContent = styled('div')`
  grid-column: main-start / main-end;
  display: grid;
  justify-items: center;
  grid-row-gap: 12px;
  padding: 24px 0;
`;

export { 
  BottomContent,
  CarouselWrapper,
  ImageContainer,
  ImageV2,
  Subheading,
  BackgroundGradient,
  Wrapper,
};
