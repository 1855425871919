import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

import useCarousel from '@glowforge/gf-styled/src/hooks/useCarousel';
import HR from '@glowforge/gf-styled/src/components/hr';

import {
  Wrapper,
  Title,
  Heading,
  Features,
  CarouselWrapper,
  TextAndImage,
  PaginationWrapper,
} from './features-display-rebrand.styles';

const FeaturesRebrand = ({ attributes, isBuilder, delay, features, sectionTitle }) => {
  const { colors } = useTheme();
  const { carouselProps, Carousel, PaginationDots } = useCarousel(delay);
  const { activeIndex, setActiveIndex } = carouselProps;

  return (
    <Wrapper
      {...attributes}
      isBuilder={isBuilder}
      variant='full'
      showOverlay={false}
      backgroundColor={colors.backgrounds.gray}>
      <Title isBuilder={isBuilder}>{sectionTitle}</Title>
      <Features isBuilder={isBuilder}>
        {features.map(({ title }, index) => {
          const isActive = index === activeIndex;
          // Make the titles fade as we go down the list.
          const opacity = isActive ? 1 : 1 - index * 0.1;
          return (
            <React.Fragment key={title}>
              <Heading
                $isActive={isActive}
                opacity={opacity}
                onClick={() => setActiveIndex(index)}>
                {title}
              </Heading>
              <HR/>
            </React.Fragment>
          );
        })}
      </Features>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Carousel {...carouselProps} isBuilder={isBuilder} Wrapper={CarouselWrapper}>
        {features.map(
          ({ title, bodyContent, image, backgroundImage, backgroundColor }) => (
            <TextAndImage
              isBuilder={isBuilder}
              key={title}
              title={title}
              bodyContent={bodyContent}
              image={image}
              backgroundColor={backgroundColor}
              backgroundImage={backgroundImage}
              minHeightMobile='400px'
              minHeightDesktop='400px'
            />
          )
        )}
      </Carousel>

      <PaginationWrapper>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <PaginationDots {...carouselProps} />
      </PaginationWrapper>
    </Wrapper>
  );
};

FeaturesRebrand.propTypes = {
  attributes: PropTypes.shape({}),
  isBuilder: PropTypes.bool,
  sectionTitle: PropTypes.string.isRequired,
  delay: PropTypes.number,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      textAndImage: PropTypes.shape({}),
    })
  ).isRequired,
};

FeaturesRebrand.defaultProps = {
  delay: undefined,
  attributes: {},
  isBuilder: false,
};

export default FeaturesRebrand;
