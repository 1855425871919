import styled from 'styled-components';

import GridContainer from '@glowforge/gf-styled/src/components/grid-container/grid-container';
import HR from '@glowforge/gf-styled/src/components/hr';
import {
  Title as UnstyledTitle,
  Heading as UnstyledHeading,
} from '@glowforge/gf-styled/src/components/typography';
import UnstyledTextAndImage, {
  ImageContainer,
  TextContainer,
} from '@glowforge/gf-styled/src/components/text-and-image';

export const CarouselWrapper = styled('div')`
  grid-column: main-start / main-end;
  user-select: none;

  @media ${(props) => props.theme.queries.desktopAndUp} {
    grid-column: ${props => props.isBuilder ? 'full-start' : 'col-start 1'} / col-end 7;
  }
`;

export const Wrapper = styled(GridContainer)`
  width: 100%;
  padding-top: 36px;
  background-color: ${(props) => props.backgroundColor};
  isolation: isolate;

  @media ${(props) => props.theme.queries.desktopAndUp} {
    padding-top: 48px;
    & ${ImageContainer} {
      max-height: 295px;
      --grid-column: col-start 8 / col-end 12;
    }

    & ${TextContainer} {
      // when inside builder, we wrap in a section that provides width constarint
      // setting to full-start and full-end appropriately allows content to fill full-width
      // of container
      --grid-column: ${props => props.isBuilder ? 'full-start' : 'col-start 1'} / col-end 10;
    }

    & ${CarouselWrapper} {
      grid-column: ${props => props.isBuilder ? 'full-start' : 'col-start 1'} / col-end 7;
    }
  }
`;

export const Title = styled(UnstyledTitle)`
  grid-column: main-start / main-end;
  padding-bottom: 42px;

  @media ${(props) => props.theme.queries.desktopAndUp} {
    grid-column:  ${props => props.isBuilder ? 'full-start' : 'col-start 1'} / col-end 7;
  }
`;

export const Features = styled('div')`
  display: none;

  @media ${(props) => props.theme.queries.desktopAndUp} {
    display: flex;
    flex-direction: column;
    grid-column: col-start 8 / ${props => props.isBuilder ? 'full-end' : 'main-end'};
    // span the whole column.
    grid-row: 1 / 3;
    // We want to set the feature list a bit below the topline of Title.
    padding-top: 32px;

    & ${HR} {
      width: 100%;
    }
  }
`;

export const Heading = styled(UnstyledHeading)`
  cursor: pointer;
  padding: 8px 0 8px 12px;
  color: ${(props) =>
    props.$isActive ? props.theme.colors.magic.base : 'inherit'};
  opacity: ${(props) => props.$opacity || 1};
  transition: color 0.2s ease-in-out, opacity 0.2s ease-in-out;
`;

export const TextAndImage = styled(UnstyledTextAndImage)`
  padding-bottom: 24px;

  & ${UnstyledHeading} {
    // We are assuming a magic.dark background here.
    // TODO: [GROW-156] Make a complimentary color picker utility.
    color: ${(props) => props.theme.colors.magic.base};
  }

  @media ${(props) => props.theme.queries.desktopAndUp} {
    padding-bottom: 48px;

    & ${ImageContainer} {
      max-height: 295px;
      --grid-column: col-start 8 / col-end 12;
    }

    & ${TextContainer} {
      --grid-column: ${props => props.isBuilder ? 'full-start' : 'col-start 1'} / col-end 9;
    }
  }
`;

export const PaginationWrapper = styled('div')`
  grid-column: main-start / main-end;
  display: flex;
  justify-content: center;
  padding-bottom: 24px;

  @media ${(props) => props.theme.queries.desktopAndUp} {
    display: none;
  }
`;
